import styled from 'styled-components';

export const ScExplanationCardWrapper = styled.div`
	display: flex;
	padding: 40px;
	flex-direction: column;
	align-items: flex-start;
	margin-top: 16px;
	margin-bottom: 16px;
	gap: 10px;
	background: ${({ theme }) => theme.colors.wheat};
	@media (min-width: 300px) {
		border-radius: 30px;
		padding: 25px;
		gap: 10px;
	}
	@media (min-width: 1220px) {
		border-radius: 30px;
		padding: 40px;
		gap: 10px;
	}
	h6 {
		margin-bottom: 20px;
		@media (min-width: 280px) {
			font: ${({ theme }) => theme.fonts.heading.xs};
		}
		@media (min-width: 768px) {
			margin-bottom: 40px;
			font: ${({ theme }) => theme.fonts.heading.small};
		}
		@media (min-width: 1440px) {
			margin-bottom: 40px;
			font: ${({ theme }) => theme.fonts.accordion.enormous};
		}
		@media (min-width: 1920px) {
			gap: 40px;
			font: ${({ theme }) => theme.fonts.accordion.large};
		}
	}
	p {
		@media (min-width: 280px) {
			font: ${({ theme }) => theme.fonts.card.textMedium};
		}
		@media (min-width: 768px) {
			font: ${({ theme }) => theme.fonts.card.textLarge};
		}
		@media (min-width: 1440px) {
			font: ${({ theme }) => theme.fonts.accordion.largeText};
		}
		@media (min-width: 1920px) {
			font: ${({ theme }) => theme.fonts.accordion.xlText};
		}
	}
	li {
		@media (min-width: 280px) {
			font: ${({ theme }) => theme.fonts.card.textMedium};
		}
		@media (min-width: 768px) {
			font: ${({ theme }) => theme.fonts.card.textLarge};
		}
		@media (min-width: 1440px) {
			font: ${({ theme }) => theme.fonts.accordion.largeText};
		}
		@media (min-width: 1920px) {
			font: ${({ theme }) => theme.fonts.accordion.xlText};
		}
	}
`;
