import React from 'react';

import { ScExplanationCardWrapper } from './styled';
import {MotionDiv} from "../../animations/components";
import {fadeInUp} from "../../animations/variants";

export const ExplanationCard = ({ title, text }: { title: string; text: string[] }) => {
	return (
		<ScExplanationCardWrapper>
			<h6 dangerouslySetInnerHTML={{ __html: title }} />
			{text.map((item) => (
				<span dangerouslySetInnerHTML={{__html: item}}/>
			))}
		</ScExplanationCardWrapper>
	);
};
